<div class="grid grid-cols-1 p-3 md:grid-cols-1">
  <p class="text-3xl font-bold">{{ "worker.worker_statistics" | translate }}: {{ fullnameWorker }}</p>
</div>

<div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900" *ngIf="workerStats">
  <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "workerProjectStats.totalRevenue" | translate }}:</div>
        <div>{{ workerStats.workerProjectStats.totalRevenue | currency : "EUR" }}</div>
      </div>
    </div>

    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "workerProjectStats.totalDebt" | translate }} :</div>
        <div>{{ workerStats.workerProjectStats.totalDebt | currency : "EUR" }}</div>
      </div>
    </div>

    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "workerProjectStats.expectedRevenue" | translate }}:</div>
        <div>{{ workerStats.workerProjectStats.expectedRevenue | currency : "EUR" }}</div>
      </div>
    </div>

    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "workerProjectStats.totalSales" | translate }}:</div>
        <div>{{ workerStats.workerProjectStats.totalSales }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "workerProjectStats.numberOfArrangements" | translate }}:</div>
        <div>{{ workerStats.workerProjectStats.numberOfArrangements }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "workerProjectStats.bruto" | translate }}:</div>
        <div>{{ workerStats.workerProjectStats.bruto | currency : "EUR" }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "workerProjectStats.profit" | translate }}:</div>
        <div>{{ workerStats.workerProjectStats.profit | currency : "EUR" }}</div>
      </div>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <div class="flex-col">
        <div>{{ "workerProjectStats.profitPending" | translate }}:</div>
        <div>{{ workerStats.workerProjectStats.profitPending | currency : "EUR" }}</div>
      </div>
    </div>
  </div>
</div>

<div class="grid grid-cols-1 gap-5 pt-3 md:grid-cols-4">
  <app-statistics [value]="entityCounter?.ticketsCount" name="tickets_sold"></app-statistics>
  <app-statistics [value]="entityCounter?.travelsCount" name="travels_sold"></app-statistics>
  <app-statistics [value]="entityCounter?.transfersCount" name="transfers_sold"></app-statistics>
  <app-statistics [value]="entityCounter?.groupTravelsCount" name="group_travels_sold"></app-statistics>
</div>
<div class="grid grid-cols-1 gap-5 pt-3 md:grid-cols-2">
  <app-horizontal-graph
    [tickets]="entityCounter?.ticketsCount"
    [travels]="entityCounter?.travelsCount"
    [transfer]="entityCounter?.transfersCount"
    [grouptravel]="entityCounter?.groupTravelsCount"
  ></app-horizontal-graph>
  <div class="p-5">
    <app-worker-sales-by-month [data]="monthlyChartData"></app-worker-sales-by-month>
  </div>
</div>

<div class="relative space-y-8">
  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      
      <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div class="datatable">
      <ng-datatable
        [rows]="sales"
        [stickyHeader]="true"
        [height]="'670px'"
        [columns]="cols"
        [sortable]="true"
        [search]="search"
        [pageSize]="pageSize"
        [totalRows]="totalRows"
        [isServerMode]="true"
        (changeServer)="changeServer($event)"
        skin="whitespace-nowrap table-hover"
        [paginationInfo]="'paginationInfoLabel' | translate"
        [noDataContent]="'empty_tables' | translate"
        [showNumbersCount]="3"
      >
      <ng-template slot="entity_name" let-value="data">
        <div class="dropdown inline-flex">
          <button
            *ngIf="getIconAndTooltip(value.entity_name)"
            type="button"
            [ngxTippy]="tooltipTemplate"
            [tippyName]="value.id"
            [tippyContext]="{ data: value }"
            [tippyProps]="{ placement: 'right', interactive: true, maxWidth: 400, theme: 'light' }"
            class="btn btn-outline-secondary rounded-circle"
          >
            <div class="flex h-6 w-6 items-center gap-2">
              <ng-container *ngIf="getIconAndTooltip(value.entity_name) as icon">
                <ng-container *ngComponentOutlet="icon"></ng-container>
              </ng-container>
            </div>
          </button>
        </div>
      </ng-template>
        <ng-template slot="createdAtFormated" let-value="data">
          {{ value.createdAt | date : "dd.MM.yyyy" }}
        </ng-template>
        <ng-template slot="destinations" let-value="data">
          <span>
            <ng-container *ngIf="value.destination !== null; else hasOnlyDestination">
              {{ value.departure?.description }} -
              {{ value.destination.description }}
            </ng-container>
            <ng-template #hasOnlyDestination>
              {{ value.destination.description }}
            </ng-template>
          </span>
        </ng-template>
        <ng-template slot="getStatus" let-value="data">
          <span *ngIf="value.status; else elseBlock" class="badge bg-success">{{ "paid" | translate }}</span>
          <ng-template #elseBlock>
            <span class="badge bg-danger">{{ "not-paid" | translate }}</span>
          </ng-template>
        </ng-template>
        <ng-template slot="bruto" let-value="data">{{ value.bruto | currency : "EUR" }}</ng-template>
        <ng-template slot="depth" let-value="data"
          ><span class="text-danger">{{ value.depth | currency : "EUR" }}</span>
        </ng-template>
        <ng-template slot="actions" let-value="data">
          <div class="flex w-max items-center justify-between">
            <div hlMenu class="dropdown">
              <a hlMenuButton href="javascript:;">
                <icon-horizontal-dots class="rotate-90 opacity-70" />
              </a>
              <div #dropdownPlaceholder *hlMenuItems>
                <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="navigateToDetails(value)">
                      <icon-info-circle class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "tickets_info.details" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" (click)="navigateToEdit(value)" *hlMenuItem="let menuItem">
                      <icon-pencil-paper class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "edit_quick_label" | translate }}
                    </a>
                  </li>
                </app-message-portal>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-datatable>
    </div>
    <ng-template #tooltipTemplate let-name let-data="data" class="bg-white">
      <container-element [ngSwitch]="data.entity_name">
        <app-transfer-plan-quickview [id]="data.entityId" *ngSwitchCase="'Transfer'"> </app-transfer-plan-quickview>
        <app-group-travel-plan-quickview [id]="data.entityId" *ngSwitchCase="'GroupTravel'"></app-group-travel-plan-quickview>
        <app-travel-quick-view [id]="data.entityId" *ngSwitchCase="'Travel'"></app-travel-quick-view>
        <app-other-services-quick-view [id]="data.entityId" *ngSwitchCase="'PlanService'"></app-other-services-quick-view>
        <app-ticket-quick-view [id]="data.entityId" *ngSwitchCase="'Ticket'"></app-ticket-quick-view>
      </container-element>
    </ng-template>
  </div>
</div>
