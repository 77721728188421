import { DailySaleDocument, Sale } from "../models/sale";
import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Ticket } from "../../tickets/models/ticket";
import { CashRegister } from "../models/cash-register";
import { Travel, TravelDraft } from "../../travels/models/travel";
import { HttpClient, HttpParams } from "@angular/common/http";
import { GenericResponse } from "src/app/shared/models/generics";
import { BaseHttpService } from "src/app/service/base.http.service";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";

@Injectable({
  providedIn: "root",
})
export class SalesService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getSalesWithHeaders(pageNumber: number, pageSize: number, filterType: string, from: Date, to: Date | undefined) {
    const apiUrl = `${this.apiUrl}/api/statistics/home`;

    const params = new HttpParams()
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString())
      .set("from", DateTimeFormatter.formatDateToString(from))
      .set("filterType", filterType)
      .set("to", DateTimeFormatter.formatDateToString(to ?? null));
    // .set('sortProperty', 'numberOfPassangers')
    // .set('sortType', 'Asc')

    return this.http.get<Sale[]>(apiUrl, { params, observe: "response" }).pipe(
      map(({ body, headers }) => {
        const customResponse: GenericResponse<Sale> = {
          body: body ?? [],
          headers: headers,
        };
        return customResponse;
      })
    );
  }

  getDrafts(): Observable<Travel[]> {
    return this.http.get<Travel[]>(`${this.apiUrl}/api/travels/drafts`);
  }

  getCashRegisters(): Observable<CashRegister[]> {
    return this.http.get<CashRegister[]>(`${this.apiUrl}/api/cashregisters`).pipe(
      map((response: CashRegister[]) => {
        return response.map((cashRegister: CashRegister) => {
          const processedAtDate = new Date(cashRegister.processedAt);
          cashRegister.processedAt = processedAtDate.toLocaleDateString("en-US");

          const processedFromDate = new Date(cashRegister.processedFrom);
          cashRegister.processedFrom = processedFromDate.toLocaleDateString("en-US");

          return cashRegister;
        });
      })
    );
  }

  getCashRegisterById(id: number): Observable<CashRegister> {
    return this.http.get<CashRegister>(`${this.apiUrl}/api/cashregisters/${id}`);
  }

  createCashRegister(cashRegister: CashRegister): Observable<CashRegister> {
    return this.http.post<CashRegister>(`${this.apiUrl}/api/cashregisters`, cashRegister);
}

  getPlansWithoutCashRegister(pageNumber: number, pageSize: number, filterType: string, from: string, to: string | undefined) {
    const params = new HttpParams()
      .set("pageNumber", pageNumber.toString())
      .set("pageSize", pageSize.toString())
      //.set("from", DateTimeFormatter.formatDateToString(from))
      .set("from", from)
      .set("filterType", filterType)
      .set("to", to ?? "");

    return this.http.get<Sale[]>(`${this.apiUrl}/api/plans/paginated`, { params, observe: "response" }).pipe(
      map(({ body, headers }) => {
        const customResponse: GenericResponse<Sale> = {
          body: body ?? [],
          headers: headers,
        };
        return customResponse;
      })
    );
  }

  updateCashRegister(cashRegister: any, cashRegisterId: number) {
    return this.http.put<CashRegister>(`${this.apiUrl}/api/cashregisters/${cashRegisterId}`, cashRegister);
  }

  deleteCashRegister(id: number) {
    return this.http.delete(`${this.apiUrl}/api/cashregisters/${id}`);
  }

  getSaleDetails(id: number): Observable<Sale> {
    return this.http.get<Sale>(`${this.apiUrl}/api/plans/${id}`);
  }

  getDailySaleDocument(id: number) {
    return this.http.post<DailySaleDocument>(`${this.apiUrl}/api/dailysaledocument/${id}`, {});
  }

  updateDailySaleDocument(id: number, dailySaleDocument: any) {
    return this.http.put<any>(`${this.apiUrl}/api/dailysaledocument/${id}`, dailySaleDocument);
  }

  deleteDraftTravel(id: number) {
    return this.http.delete<any>(`${this.apiUrl}/api/draft/travel/${id}`);
  }
  getPlan(): Observable<Sale[]> {
    return this.http.get<Sale[]>(`${this.apiUrl}/api/Plans/all`);
  }
  sendPayment(id: number): (body: FormData) => Observable<any> {
    return (body: FormData) => {
      body.append("PaymentId", id.toString());
      return this.http.post(`${this.apiUrl}/api/Payments/send`, body);
    };
  }
  stringToHtmlFile(htmlContent: string, fileName: string): File {
    const blob = new Blob([htmlContent], { type: "text/html" });
    return new File([blob], fileName, { type: "text/html" });
  }
  getExchangeRate(): Observable<any> {
  return this.http.get<any>(`${this.apiUrl}/api/Plans/exchange-rate`);
  }
}

interface DraftResponse {
  travelDrafts: TravelDraft[];
  ticketDrafts: Ticket[];
}
