import { throwError } from 'rxjs';
import * as moment from "moment";
import { Sale } from "../models/sale";
import { Router } from "@angular/router";
import { Component, ViewChild } from "@angular/core";
import { ModalComponent } from "angular-custom-modal";
import { CashRegister } from "../models/cash-register";
import { TranslateService } from "@ngx-translate/core";
import { SalesService } from "../services/sales.service";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatatableColumn } from 'src/app/shared/components/base-table/base-table.component';
import { colDef } from '@bhplugin/ng-datatable';

@Component({
  selector: "app-daily-sale",
  templateUrl: "./daily-sale.component.html",
})
export class DailySaleComponent {
  @ViewChild("viewTaskModal") viewTaskModal!: ModalComponent;
  @ViewChild("addSaleInCashRegister") addSaleModal!: ModalComponent;
  @ViewChild("addCashRegisterModal") addCashRegisterModal!: ModalComponent;

  searchTask = "";
  plans: Sale[] = [];
  checked!: boolean;
  date = "2023-12-31";
  selectedTab!: number;
  isShowTaskMenu = false;
  public form!: FormGroup;
  pagedTasks: any = [];
  filteredTaskss: any = [];
  selectedPlans: any[] = [];
  plansWithoutCashRegister: any[] = [];
  cashRegisters: CashRegister[] = [];
  cashRegisterId: number | undefined;
  loading = false;
  display!: boolean;
  fromDate!: string;
  toDate!: string;

  constructor(public fb: FormBuilder, public saleService: SalesService, public translate: TranslateService, private router: Router) {}
  pagerWithoutCashRegister = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
    totalCount: 0,
    startIndex: 0,
    endIndex: 0,
  };

  ngOnInit() {
    this.getCashRegisters();
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      processedAt: ["", Validators.required],
      processedFrom: ["", Validators.required],
    });
  }

  searchTasks() {
    console.log("search..");
  }
  tabChanged(id: number) {
    this.selectedTab = id;
    this.getCashRegisterById(id);
    this.cashRegisterId = id;

  }

  openModal() {
    this.isShowTaskMenu = false;
    this.addCashRegisterModal.open();
  }
  closeDocument() {
    this.addCashRegisterModal.close();
  }

  saveCashRegister() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.createCashRegister();
  }

  fetchCashRegister(): CashRegister {
    const { processedAt, processedFrom } = this.form.value;
    return { processedAt, processedFrom } as CashRegister;
  }

  getCashRegisters() {
    this.saleService.getCashRegisters().subscribe((res) => {
      this.cashRegisters = res;
    });
  }

  getCashRegisterById(id: number) {
    this.saleService.getCashRegisterById(id).subscribe((res) => {
      this.plans = res.plans;
      this.fromDate = this.formatDate(res.processedAt);
      this.toDate = this.formatDate(res.processedFrom);
      this.display = true;
    });
  }

  formatDate(date: string): string {
    return moment(date).format("YYYY-MM-DD");
  }

  createCashRegister() {
    this.saleService.createCashRegister(this.fetchCashRegister()).subscribe(
      (res) => {
        this.addSaleModal.close();
        this.getCashRegisters();
        showMessage(PopupType.Success, this.translate.instant("cash_register.created_successfully"));
      },
      (error) => {
        showMessage(PopupType.Danger, error.toString());
      }
    );
  }
  handleFromDateChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.fromDate = input.value;
    this.getPlansWithoutCashRegister();
  }

  handleToDateChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.toDate = input.value;
    this.getPlansWithoutCashRegister();
  }

  openSaleModal() {
    if (this.selectedTab === undefined) {
      showMessage(PopupType.Warning, this.translate.instant("cash_register.select_cash_register"));
      return;
    }
    this.addSaleModal.open();
    this.getPlansWithoutCashRegister();
  }

  addPlanInCashRegister(id: number) {
    const plan = this.plansWithoutCashRegister.find((plan: any) => plan.id === id);
    if (plan.checked) {
      plan.checked = false;
      this.selectedPlans = this.selectedPlans.filter((selectedPlan) => selectedPlan.id !== id);
      return;
    }
    plan.checked = true;
    this.selectedPlans.push(plan);
  }

  addPlansInCashRegister() {
    if (this.selectedPlans.length === 0) {
      showMessage(PopupType.Warning, this.translate.instant("cash_register.select_plans"));
      return;
    }
    if (this.selectedTab === null) return;
    this.saleService.updateCashRegister(this.updateCashRegister(), this.selectedTab).subscribe(
      (res) => {
        this.addSaleModal.close();
        this.selectedPlans = [];
        this.getCashRegisterById(this.selectedTab);
        showMessage(PopupType.Success, this.translate.instant("cash_register.added_plans"));

        this.getCashRegisters();
      },
      (error) => {
        showMessage(PopupType.Danger, error.toString());
      }
    );
  }

  getPlansWithoutCashRegister() {
    this.saleService.getPlansWithoutCashRegister(this.pagerWithoutCashRegister.currentPage, this.pagerWithoutCashRegister.pageSize, "WithoutCashRegister", this.fromDate, this.toDate).subscribe((res) => {
      this.plansWithoutCashRegister = res.body;
      const pagination = JSON.parse(res.headers.get("X-Pagination") || "");
      this.pagerWithoutCashRegister.totalPages = pagination.TotalPages;
      this.pagerWithoutCashRegister.currentPage = pagination.CurrentPage;
      this.pagerWithoutCashRegister.pageSize = pagination.PageSize;
      this.pagerWithoutCashRegister.totalCount = pagination.TotalCount;
    });
  }

  updateCashRegister() {
    const cashRegister = this.cashRegisters.find((cashRegister) => cashRegister.id === this.selectedTab);
    const planIDs = this.selectedPlans.map((plan) => plan.id);
    const updatedAt = new Date().toISOString();
    const processedAt = cashRegister ? new Date(cashRegister.processedAt).toISOString() : "";
    const processedFrom = cashRegister ? new Date(cashRegister.processedFrom).toISOString() : "";
    const createdAt = cashRegister ? new Date(cashRegister.createdAt).toISOString() : "";
    const updatedCashRegister = { processedAt, processedFrom, createdAt, updatedAt, planIDs };
    return updatedCashRegister;
  }

  handleDateChange(event: any) {
    this.date = moment(event.target.value).format("YYYY-MM-DD");
    this.getPlansWithoutCashRegister();
  }

  openDocument() {
    if (this.cashRegisterId === undefined) {
      showMessage(PopupType.Warning, this.translate.instant("cash_register.select_cash_register"));
      return;
    }
    this.router.navigate([`/dashboard/daily-sale/document/${this.cashRegisterId}`]);
  }

    cols: colDef[] = [
      { field: "check", title: this.translate.instant("cash_register.check"), hide: false } as DatatableColumn,
      { field: "customer", title: this.translate.instant("cash_register.customer"), hide: false } as DatatableColumn,
      { field: "purchased", title: this.translate.instant("cash_register.purchased"), hide: false } as DatatableColumn,
      { field: "status", title: this.translate.instant("cash_register.status"), hide: false } as DatatableColumn,
      { field: "sold", title: this.translate.instant("cash_register.sold"), hide: false } as DatatableColumn,
      { field: "advance_payment", title: this.translate.instant("cash_register.advance_payment"), hide: false } as DatatableColumn,
      { field: "total_price", title: this.translate.instant("cash_register.total_price"), hide: false } as DatatableColumn,
      { field: "payment_deadline", title: this.translate.instant("cash_register.payment_deadline"), hide: false } as DatatableColumn,
    ];
    colsWithoutCheck: colDef[] = [
      { field: "customer", title: this.translate.instant("cash_register.customer"), hide: false } as DatatableColumn,
      { field: "purchased", title: this.translate.instant("cash_register.purchased"), hide: false } as DatatableColumn,
      { field: "status", title: this.translate.instant("cash_register.status"), hide: false } as DatatableColumn,
      { field: "sold", title: this.translate.instant("cash_register.sold"), hide: false } as DatatableColumn,
      { field: "advance_payment", title: this.translate.instant("cash_register.advance_payment"), hide: false } as DatatableColumn,
      { field: "total_price", title: this.translate.instant("cash_register.total_price"), hide: false } as DatatableColumn,
      { field: "payment_deadline", title: this.translate.instant("cash_register.payment_deadline"), hide: false } as DatatableColumn,
    ];
}