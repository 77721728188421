import { Sale } from "../../sales/models/sale";
import { colDef } from "@bhplugin/ng-datatable";
import { ActivatedRoute } from "@angular/router";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { CompanyStatistics } from "../models/company";
import { ApexChart, ApexLegend } from "ng-apexcharts";
import { TranslateService } from "@ngx-translate/core";
import { CompaniesService } from "./../services/companies.service";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { MessagePortalComponent } from "src/app/portal/portal";
import { animate, style, transition, trigger } from "@angular/animations";
import { PositioningService } from "src/app/shared/services/positioning.service";

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class CompanyComponent implements OnInit {
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  private id!: number;
  protected companyData!: CompanyStatistics;
  public chartOptions: any;
  public chartData: any[] = [];
  public sales: Sale[] = [];
  public pageSize = 10;
  public pageNumber = 1;
  public totalRows = 1;
  monthlyChartOptions: any;
  public exportApiUrl!: string;
  public additionalInfo!: string;

  public filterType = "None";

  search = "";
  cols: colDef[] = [
    { field: "id", title: "ID", isUnique: true, hide: true },
    { field: "entity_name", title: this.translate.instant("companyinfo.entity_name"), hide: false },
    { field: "customerFullName", title: this.translate.instant("companyinfo.customerFullName"), hide: false },
    { field: "customerPhoneNumber", title: this.translate.instant("companyinfo.customerPhoneNumber"), hide: false },
    { field: "destination.description", title: this.translate.instant("companyinfo.destination"), hide: false },
    { field: "number_of_passangers", title: this.translate.instant("companyinfo.number_of_passangers"), hide: false },
    { field: "status", title: this.translate.instant("companyinfo.status"), hide: false },
    { field: "bruto", title: this.translate.instant("companyinfo.bruto"), hide: false },
    { field: "depth", title: this.translate.instant("companyinfo.depth"), hide: false },
    { field: "event_starts", title: this.translate.instant("companyinfo.event_starts"), hide: false },
    { field: "event_ends", title: this.translate.instant("companyinfo.event_ends"), hide: false },
  ];
  exportCols = [
    { field: "customerNameAndSurname", title: this.translate.instant("companyexport.customerNameAndSurname"), hide: false, sort: false } as DatatableColumn,
    { field: "phoneNumber", title: this.translate.instant("companyexport.phoneNumber"), hide: false } as DatatableColumn,
    { field: "numberOfPersons", title: this.translate.instant("companyexport.numberOfPersons"), hide: false } as DatatableColumn,
    { field: "country", title: this.translate.instant("companyexport.country"), hide: false } as DatatableColumn,
    { field: "city", title: this.translate.instant("companyexport.city"), hide: false } as DatatableColumn,
    { field: "departureDate", title: this.translate.instant("companyexport.departureDate"), hide: false } as DatatableColumn,
    { field: "returnDate", title: this.translate.instant("companyexport.returnDate"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("companyexport.bruto"), hide: false, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("companyexport.neto"), hide: false, sort: false } as DatatableColumn,
    { field: "profit", title: this.translate.instant("companyexport.profit"), hide: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("companyexport.status"), hide: false } as DatatableColumn,
    { field: "purchaseDate", title: this.translate.instant("companyexport.purchaseDate"), hide: false } as DatatableColumn,
    //{ field: "user", title: this.translate.instant("companyexport.user"), hide: false } as DatatableColumn,
  ];


  chart: ApexChart = {
    height: 300,
    type: "radar",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  };
  legend: ApexLegend = {
    position: "bottom",
  };

  pieChart = {
    series: [
      {
        name: "Series 1",
        data: [80, 50, 30, 40, 100, 20, 12, 12, 12, 12, 12, 12],
      },
    ],
    labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
    colors: ["#4361ee"],
    xaxis: {
      categories: ["January", "February", "March", "April", "May", "June", "July", "Avgust", "September", "October", "November", "December"],
    },
    plotOptions: {
      radar: {
        polygons: {
          // strokeColors: isDark ? '#191e3a' : '#e0e6ed',
          // connectorC olors: isDark ? '#191e3a' : '#e0e6ed',
          strokeColors: "#e0e6ed",
          connectorColors: "#e0e6ed",
        },
      },
    },
    tooltip: {
      // theme: isDark ? 'dark' : 'light',
      theme: "light",
    },
  };

  constructor(private companiesService: CompaniesService, private route: ActivatedRoute, public translate: TranslateService, private positioningService: PositioningService) {
    this.monthlyChartOptions = {}; // Inicijalizujemo monthlyChartOptions praznim objektom
  }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.fetchCompanyRecords();
      this.fetchCompanyStatistics();
    });
    this.exportApiUrl = `api/Companies/companies/${this.id}`;
  }

  fetchCompanyRecords() {
    this.companiesService.getCompaniesRecords(this.id, this.pageNumber, this.pageSize, this.filterType).subscribe((response) => {
      this.sales = response.body;

      const pagination = JSON.parse(response.headers.get("X-Pagination") || "");
      this.pageNumber = pagination.CurrentPage;
      this.pageSize = pagination.PageSize;
      this.totalRows = pagination.TotalCount;
    });
    this.exportApiUrl = `api/Companies/companies/${this.id}`;
  }

  fetchCompanyStatistics() {
    this.companiesService.getCompanyStatistics(this.id).subscribe((response) => {
      this.companyData = response;
      const visitsPerYear: { [key: string]: number } = {};
      this.additionalInfo = [response.name, response.address, response.phoneNumber, response.email].filter(value => value).join(' ');
      this.companyData.webChartData.forEach((data) => {
        const year = data.year.toString();
        visitsPerYear[year] = (visitsPerYear[year] || 0) + data.numberOfPlans;
      });

      const chartHeight = this.chartData.length < 3 ? 150 : 350;
      const chartWidth = this.chartData.length < 3 ? 150 : 350;

      this.chartData = Object.keys(visitsPerYear).map((year) => ({
        x: year,
        y: visitsPerYear[year],
      }));

      this.chartOptions = {
        series: [
          {
            name: "Visits",
            data: this.chartData,
            color: "#46b196",
          },
        ],
        chart: {
          height: chartHeight,
          width: chartWidth,
          type: "bar",
          events: {
            dataPointSelection: (event: any, chartContext: any, config: any) => {
              const year = config.w.config.series[0].data[config.dataPointIndex].x;
              this.showMonthlyChart(year);
            },
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
            barHeight: "75%",
            dataLabels: {
              position: "bottom",
            },
            colors: {
              ranges: [
                { from: 0, to: 5, color: "#FFB6C1" },
                { from: 6, to: 10, color: "#FF69B4" },
                { from: 11, to: 15, color: "#FF1493" },
                { from: 16, to: 20, color: "#C71585" },
                { from: 21, to: 25, color: "#DA70D6" },
                { from: 26, to: 30, color: "#BA55D3" },
                { from: 31, color: "#8A2BE2" },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
            },
          },
        },
        dataLabels: { enabled: false },
        xaxis: {
          type: "category",
          categories: this.chartData.map((item) => item.x),
        },

        yaxis: { title: { text: "" } },
        legend: { show: false },
      };
    });
  }
  updateColumn(col: colDef) {
    col.hide = !col.hide;
    this.cols = [...this.cols]; // Create a new reference of the array
  }

  changeServer(data: any) {
    this.pageSize = data.pagesize;
    this.pageNumber = data.current_page;
    this.fetchCompanyRecords();
    this.fetchCompanyStatistics();
  }
  showMonthlyChart(year: string) {
    const monthlyDataForYear = this.companyData.webChartData.filter((data) => data.year.toString() === year);
    const chartWidth = this.chartData.length < 3 ? 150 : 350;

    const groupedData = new Map<string, number>();
    for (const data of monthlyDataForYear) {
      const date = new Date();
      date.setMonth(data.month - 1);
      const monthName = date.toLocaleString("en-US", { month: "long" });

      const currentCount = groupedData.get(monthName) || 0;
      groupedData.set(monthName, currentCount + data.numberOfPlans);
    }

    const monthlyChartData = Array.from(groupedData.entries()).map(([month, numberOfPlans]) => ({ x: month, y: numberOfPlans }));

    const monthlyChartOptions = {
      series: [{
        name: "", data: monthlyChartData.map((item) => item.y),
        color: "#46b196"
      }],
      chart: { height: 350, type: "line" },
      plotOptions: {
        bar: {
          horizontal: true,
          width: chartWidth,
          endingShape: "rounded",
          colors: monthlyChartData.map(() => "#4CAF50"),
        },
      },
      dataLabels: { enabled: false },
      xaxis: { categories: monthlyChartData.map((item) => item.x) },
      yaxis: { title: { text: "" } },
      legend: { show: false },
    };

    this.monthlyChartOptions = monthlyChartOptions;
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

}
