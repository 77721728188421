import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";
import { GroupTravelsService } from "../services/group-travels.service";
import { GroupTravel, GroupTravelPassanger } from "../models/group-travel";
import { TranslateService } from "@ngx-translate/core";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

@Component({
	selector: "app-group-travel-passangers-table",
	templateUrl: "./group-travel-passangers.table.component.html",
})
export class GroupTravelPassangersTableComponent implements OnInit {
	@Input() groupTravelId: number | undefined;
	public exportApiUrl!: string;

	groupTravel!: GroupTravel;
	groupTravelPassangers!: any;
	counter = 0;
	currentPage = 1;
	pageSize = 2;
	paginatedData: any[] = [];
	groupTravelData: any[] = [];
	cols = [
		{ field: "id", title: this.translate.instant("group_travel_table.id") } as DatatableColumn,
		{ field: "name", title: this.translate.instant("group_travel_table.firstname") } as DatatableColumn,
		{ field: "surname", title: this.translate.instant("group_travel_table.lastname") } as DatatableColumn,
		{ field: "dateOfBirth", title: this.translate.instant("group_travel_table.date_of_birth") } as DatatableColumn,
		{ field: "address", title: this.translate.instant("group_travel_table.address") } as DatatableColumn,
		{ field: "phoneNumber", title: this.translate.instant("group_travel_table.phone_number") } as DatatableColumn,
		{ field: "lifeStage", title: this.translate.instant("group_travel_table.ilife_stage") } as DatatableColumn,
		{ field: "passangerState", title: this.translate.instant("group_travel_table.passanger_state") } as DatatableColumn,
		{ field: "totalPrice", title: this.translate.instant("group_travel_table.total_price") } as DatatableColumn,
		{ field: "groupNumber", title: this.translate.instant("group_travel_table.group_number") } as DatatableColumn,
	];
	public exportCols = [
		{ field: "id", title: this.translate.instant("grouptravel_export.planId"), isUnique: true, hide: true } as DatatableColumn,
		{ field: "customerNameAndSurname", title: this.translate.instant("grouptravel_export.customerNameAndSurname"), hide: false, sort: false } as DatatableColumn,
		{ field: "customerPhoneNumber", title: this.translate.instant("grouptravel_export.customerPhoneNumber"), hide: false } as DatatableColumn,
		// { field: "numberOfPassangers", title: this.translate.instant("grouptravel_export.numberOfPassangers"), hide: false } as DatatableColumn,
		{ field: "destination", title: this.translate.instant("grouptravel_export.destination"), hide: false } as DatatableColumn,
		{ field: "roomType", title: this.translate.instant("grouptravel_export.roomType"), hide: false } as DatatableColumn,
		{ field: "serviceType", title: this.translate.instant("grouptravel_export.serviceType"), hide: true, sort: false } as DatatableColumn,
		{ field: "bruto", title: this.translate.instant("grouptravel_export.bruto"), hide: true, sort: false } as DatatableColumn,
		{ field: "neto", title: this.translate.instant("grouptravel_export.neto"), hide: true, sort: false } as DatatableColumn,
		{ field: "eTuristStatus", title: this.translate.instant("grouptravel_export.eTuristStatus"), hide: true, sort: false } as DatatableColumn,
		{ field: "status", title: this.translate.instant("grouptravel_export.status"), hide: true, sort: false } as DatatableColumn,
		{ field: "saleDate", title: this.translate.instant("grouptravel_export.saleDate"), hide: true, sort: false } as DatatableColumn,
		{ field: "user", title: this.translate.instant("grouptravel_export.user"), hide: true } as DatatableColumn,
	];

	rows: any[] = [];
	jsonData = this.rows;

	constructor(private groupTravelService: GroupTravelsService, private router: Router, public translate: TranslateService, private route: ActivatedRoute) { }

	ngOnInit(): void {
		this.fetchTravelData();
	}

	fetchTravelData() {
		if (this.groupTravelId !== undefined) {
			this.groupTravelService.getGroupTravelData(this.groupTravelId).subscribe((response) => {
				this.groupTravel = response;
				this.groupTravelPassangers = this.transformGroupTravelData(this.groupTravel);
				this.groupTravelPassangers.flatMap((element: any) => {
					element.passengers.forEach((passenger: any) => {
						passenger.customer.lifeStage = passenger.lifeStage;
						passenger.customer.groupNumber = element.plandId;
						if (passenger.customerId === element.passengers[0].customer.id) {
							passenger.customer.passangerState = element.planStatus ? "Paid" : "Unpaid";
							passenger.customer.totalPrice = "";
						}
						this.rows.push(passenger.customer);
					});
				});
				this.exportApiUrl = `api/GroupTravels/group-travels/${this.groupTravelId}`;
				this.updatePaginatedData();
			});
		}
	}

	transformGroupTravelData(groupTravel: GroupTravel) {
		return (
			groupTravel.groupTravelPlans?.map((groupTravelPlan) => {
				let passengers: GroupTravelPassanger[] = groupTravelPlan.plan.groupTravelPlans?.flatMap((p) => p.groupTravelPassangers) || [];
				const customer = groupTravelPlan?.plan?.groupTravelPlans?.[0]?.customer;
				const mainCustomer = passengers.find((passenger) => passenger?.customer?.id === customer?.id);
				passengers = passengers.filter((passenger) => passenger?.customerId !== customer?.id);
				if (mainCustomer) passengers.unshift(mainCustomer);

				return {
					planStatus: groupTravelPlan.plan.status,
					plandId: groupTravelPlan.planId,
					planid: groupTravelPlan.id,
					totalPrice: groupTravelPlan.plan.bruto - groupTravelPlan.plan.neto,
					passengers: passengers,
					paymentType: groupTravelPlan.plan.paymentType?.title,
					avans: groupTravelPlan.plan.avans ?? 0,
				};
			}) || []
		);
	}

	excelColumns() {
		return {
			Id: "id",
			FirstName: "firstName",
			LastName: "lastName",
			DateOfBirth: "dateOfBirth",
			Address: "address",
			Contact: "contaxt",
			LifeStage: "lifeStage",
			PassangerState: "status",
			TotalPrice: "totalPrice",
			GroupNumber: "groupNumber",
		};
	}

	excelItems() {
		return this.rows;
	}

	updatePaginatedData(): void {
		const startIndex = (this.currentPage - 1) * this.pageSize;
		const endIndex = Math.min(startIndex + this.pageSize, this.groupTravelPassangers.length);
		this.paginatedData = this.groupTravelPassangers.slice(startIndex, endIndex);
	}

	goToPage(page: number): void {
		this.currentPage = page;
		this.updatePaginatedData();
	}

	nextPage(): void {
		if (this.currentPage < this.totalPages()) {
			this.currentPage++;
			this.updatePaginatedData();
		}
	}

	previousPage(): void {
		if (this.currentPage > 1) {
			this.currentPage--;
			this.updatePaginatedData();
		}
	}

	totalPages(): number {
		return Math.ceil(this.groupTravelPassangers.length / this.pageSize);
	}
  getSerialNumber(groupIndex: number, passengerIndex: number): number {
    const previousGroups = this.paginatedData.slice(0, groupIndex);
    const previousPassengers = previousGroups.reduce((acc, group) => acc + group.passengers.length, 0);
    return previousPassengers + passengerIndex + 1;
  }
}
