<div>
  <form class="space-y-5 p-5" [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-4">
      <div *ngIf="!hideNameAndSurname">
        <label *ngIf="ifMainCustomer && !hideNameAndSurname" for="customerSearch">{{ "customer_checkout.search_customer" | translate }}</label>
        <label *ngIf="!ifMainCustomer && !hideNameAndSurname" for="customerSearch">{{ "customer_checkout.search_passanger" | translate }}</label>
        <app-autocomplete-remote
          [type]="EntityPicklistType.Customers"
          labelName="fullName"
          placeholder="{{ 'customer_checkout.search_customer_placeholder' | translate }}"
          labelForId="customerSearch"
          [minTermLength]="4"
          [changedCallback]="initForm.bind(this)"
          [addCallback]="setCustomerNameSurname.bind(this)"
        >
        </app-autocomplete-remote>
      </div>
      <div *ngIf="hideNameAndSurname" [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customName">{{ "customer_checkout.name" | translate }}</label>
        <input id="customName" type="text" placeholder="{{ 'customer_checkout.enter_name' | translate }}" class="form-input" formControlName="name" />
        <ng-container *ngIf="form.controls['name'].touched && form.controls['name'].errors">
          <p class="mt-1 text-danger">{{ "customer_checkout.please_fill_name" | translate }}</p>
        </ng-container>
      </div>
      <div *ngIf="hideNameAndSurname" [ngClass]="form.controls['surname'].touched ? (form.controls['surname'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customSurname">{{ "customer_checkout.surname" | translate }}</label>
        <input id="customSurname" type="text" placeholder="{{ 'customer_checkout.enter_surname' | translate }}" class="form-input" formControlName="surname" />
        <ng-container *ngIf="form.controls['surname'].touched && form.controls['surname'].errors">
          <p class="mt-1 text-danger">{{ "customer_checkout.please_fill_surname" | translate }}</p>
        </ng-container>
      </div>
      <div *ngIf="ifMainCustomer" class="flex items-center justify-evenly" [ngClass]="{ 'text-success': form.controls['mainCustomer'].value, 'text-danger': !form.controls['mainCustomer'].value }">
        <label class="radio-container mt-1 inline-flex cursor-pointer" for="mainCustomer">
          <input type="checkbox" class="form-checkbox" id="mainCustomer" formControlName="mainCustomer" checked="this.defaultCustomer.lifeStage == 'mainCustomer'" />
          <span *ngIf="form.controls['mainCustomer'].value; else alternateTranslation">
            {{ "customer_checkout.main_customer" | translate }}
          </span>
          <ng-template #alternateTranslation>
            {{ "customer_checkout.not_main_customer" | translate }}
          </ng-template>
        </label>
      </div>
      <div *ngIf="withReservation" [ngClass]="form.controls['reservationNumber'].touched ? (form.controls['reservationNumber'].errors ? 'has-error' : 'has-success') : ''">
        <label for="reservationNumber">{{ "customer_checkout.reservation_number" | translate }}</label>
        <input id="reservationNumber" type="text" placeholder="{{ 'customer_checkout.reservation_number_placholder' | translate }}" class="form-input" formControlName="reservationNumber" />
        <ng-container *ngIf="form.controls['reservationNumber'].touched && !form.controls['reservationNumber'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "customer_checkout.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['reservationNumber'].touched && form.controls['reservationNumber'].errors">
          <p class="mt-1 text-danger">{{ "customer_checkout.please_provide_reservation_num" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
      <div [ngClass]="form.controls['phoneNumber'].touched ? (form.controls['phoneNumber'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customPhoneNumber">{{ "customer_checkout.phone_number" | translate }}</label>
        <input id="customPhoneNumber" type="text" placeholder="{{ 'customer_checkout.enter_phone_number' | translate }}" class="form-input" formControlName="phoneNumber" />
        <ng-container *ngIf="form.controls['phoneNumber'].touched && form.controls['phoneNumber'].errors">
          <p class="mt-1 text-danger">{{ "customer_checkout.please_provide_phone" | translate }}</p>
        </ng-container>
      </div>
      <div [ngClass]="form.controls['email'].touched ? (form.controls['email'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customEmail">{{ "customer_checkout.email" | translate }}</label>
        <input id="customEmail" type="text" placeholder="{{ 'customer_checkout.enter_email' | translate }}" class="form-input" formControlName="email" />
        <ng-container *ngIf="form.controls['email'].touched && !form.controls['email'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "customer_checkout.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['email'].touched && form.controls['email'].errors">
          <p class="mt-1 text-danger">{{ "customer_checkout.please_provide_email" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-2" [ngClass]="hiddenDetails ? 'hidden' : ''">
      <div [ngClass]="form.controls['dateOfBirth'].touched ? (form.controls['dateOfBirth'].errors ? 'has-error' : 'has-success') : ''">
        <label for="dateOfBirth">{{ 'customer_create.date_of_birth' | translate }}</label>
        <input
          id="dateOfBirth"
          type="text"
          class="form-input"
          formControlName="dateOfBirth"
          placeholder="__.__.____"
          [textMask]="{ mask: dateMask }"
        />
        <ng-container *ngIf="form.controls['dateOfBirth'].touched && form.controls['dateOfBirth'].errors">
          <p class="text-danger">{{ 'customer_create.please_provide_date_of_birth' | translate }}</p>
        </ng-container>
      </div>      
      <div [ngClass]="form.controls['imbg'].touched ? (form.controls['imbg'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customImbg">{{ "customer_checkout.imbg" | translate }}</label>
        <input id="customImbg" type="text" placeholder="{{ 'customer_checkout.enter_imbg' | translate }}" class="form-input" formControlName="imbg" />
        <ng-container *ngIf="form.controls['imbg'].touched && !form.controls['imbg'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "customer_checkout.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['imbg'].touched && form.controls['imbg'].errors">
          <p class="mt-1 text-danger">{{ "customer_checkout.please_provide_imbg" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-2" [ngClass]="hiddenDetails ? 'hidden' : ''">
      <div [ngClass]="form.controls['address'].touched ? (form.controls['address'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customAddress">{{ "customer_checkout.address" | translate }}</label>
        <input id="customAddress" type="text" placeholder="{{ 'customer_checkout.enter_address' | translate }}" class="form-input" formControlName="address" />
        <ng-container *ngIf="form.controls['address'].touched && !form.controls['address'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "customer_checkout.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['address'].touched && form.controls['address'].errors">
          <p class="mt-1 text-danger">{{ "customer_checkout.please_provide_address" | translate }}</p>
        </ng-container>
      </div>
      <div [ngClass]="form.controls['profession'].touched ? (form.controls['profession'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customProfession">{{ "customer_checkout.profession" | translate }}</label>
        <input id="customProfession" type="text" placeholder="{{ 'customer_checkout.enter_profession' | translate }}" class="form-input" formControlName="profession" />
        <ng-container *ngIf="form.controls['profession'].touched && form.controls['profession'].errors">
          <p class="mt-1 text-danger">{{ "customer_checkout.please_provide_profession" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-3" [ngClass]="hiddenDetails ? 'hidden' : ''">
      <div [ngClass]="form.controls['passport'].touched ? (form.controls['passport'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customPassportNumber">{{ "customer_checkout.passport_number" | translate }}</label>
        <input id="customPassportNumber" type="text" placeholder="{{ 'customer_checkout.enter_passport_number' | translate }}" class="form-input" formControlName="passport" />
        <ng-container *ngIf="form.controls['passport'].touched && !form.controls['passport'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "customer_checkout.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['passport'].touched && form.controls['passport'].errors">
          <p class="mt-1 text-danger">{{ "customer_checkout.please_provide_passport_number" | translate }}</p>
        </ng-container>
      </div>
      <div [ngClass]="form.controls['passportExpirationDate'].touched ? (form.controls['passportExpirationDate'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customPassportExpirationDate">{{ "customer_checkout.passport_expiration_date" | translate }}</label>
        <input id="customPassportExpirationDate" type="text" class="form-input" formControlName="passportExpirationDate" placeholder="__.__.____" [textMask]="{ mask: dateMask }" />
        <ng-container *ngIf="form.controls['passportExpirationDate'].touched && !form.controls['passportExpirationDate'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "customer_checkout.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['passportExpirationDate'].touched && form.controls['passportExpirationDate'].errors">
          <p class="mt-1 text-danger">{{ "customer_checkout.please_provide_passport_expiration_date" | translate }}</p>
        </ng-container>
      </div>
      <div [ngClass]="form.controls['passportIssuingDate'].touched ? (form.controls['passportIssuingDate'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customPassportIssuingDate">{{ "customer_checkout.passport_issuing_date" | translate }}</label>
        <input id="customPassportIssuingDate" type="text" class="form-input" placeholder="__.__.____" [textMask]="{ mask: dateMask }" formControlName="passportIssuingDate" />
        <ng-container *ngIf="form.controls['passportIssuingDate'].touched && !form.controls['passportIssuingDate'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "customer_checkout.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['passportIssuingDate'].touched && form.controls['passportIssuingDate'].errors">
          <p class="mt-1 text-danger">{{ "customer_checkout.please_provide_passport_issuing_date" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <ng-content></ng-content>
    <div class="grid grid-cols-2 gap-5" *ngIf="passanger">
      <button type="submit" [disabled]="form.untouched" class="btn btn-primary w-full">{{ "customer_checkout.submit" | translate }}</button>
      <button type="button" class="btn btn-outline-primary w-full" (click)="toggleHiddenDetails()">
        <span *ngIf="hiddenDetails; else showLessText">{{ "customer_checkout.show_more" | translate }}</span>
        <ng-template #showLessText>
          {{ "customer_checkout.show_less" | translate }}
        </ng-template>
      </button>
    </div>
  </form>
</div>
