import { Component, ElementRef, ViewChild } from "@angular/core";
import { Sale } from "../../sales/models/sale";
import { ActivatedRoute } from "@angular/router";
import { colDef } from "@bhplugin/ng-datatable";
import { TranslateService } from "@ngx-translate/core";
import { OrganizerStatistics } from "../models/oragnizers";
import { OrganizersService } from "../services/organizers.service";
import { MessagePortalComponent } from "src/app/portal/portal";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { animate, style, transition, trigger } from "@angular/animations";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

@Component({
  selector: "app-organizer",
  templateUrl: "./organizer.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class OrganizerComponent {
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  private id!: number;
  protected organizerData!: OrganizerStatistics;
  public chartOptions: any;
  public chartData: any[] = [];
  public sales: Sale[] = [];
  public pageSize = 10;
  public pageNumber = 1;

  monthlyChartOptions: any;
  public totalRows = 1;
  public additionalInfo!: string;
  public filterType = "None";
  exportApiUrl!: string;
  search = "";

  cols = [
    { field: "id", title: "ID", isUnique: true, hide: true },
    { field: "entity_name", title: this.translate.instant("organizerinfo.entity_name"), hide: false },
    { field: "customerFullName", title: this.translate.instant("organizerinfo.customerFullName"), hide: false },
    { field: "customerPhoneNumber", title: this.translate.instant("organizerinfo.customerPhoneNumber"), hide: false },
    { field: "destination.description", title: this.translate.instant("organizerinfo.destination"), hide: false },
    { field: "number_of_passangers", title: this.translate.instant("organizerinfo.number_of_passangers"), hide: false },
    { field: "status", title: this.translate.instant("organizerinfo.status"), hide: false },
    { field: "bruto", title: this.translate.instant("organizerinfo.bruto"), hide: false },
    { field: "depth", title: this.translate.instant("organizerinfo.depth"), hide: false },
    { field: "createdAt", title: this.translate.instant("organizerinfo.event_starts"), hide: false },
    { field: "updatedAt", title: this.translate.instant("organizerinfo.event_ends"), hide: false },
  ];


  exportCols = [
    { field: "entity", title: this.translate.instant("organizer_info.entity"), hide: false, sort: false } as DatatableColumn,
    { field: "customerNameAndSurname", title: this.translate.instant("organizer_info.customerNameAndSurname"), hide: false } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("organizer_info.customerPhoneNumber"), hide: false } as DatatableColumn,
    { field: "destinations", title: this.translate.instant("organizer_info.destinations"), hide: false } as DatatableColumn,
    { field: "numberOfPassangers", title: this.translate.instant("organizer_info.numberOfPassangers"), hide: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("organizer_info.status"), hide: false, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("organizer_info.neto"), hide: false, sort: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("organizer_info.bruto"), hide: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("organizer_info.status"), hide: false } as DatatableColumn,
    { field: "eventStartDate", title: this.translate.instant("organizer_info.eventStartDate"), hide: false } as DatatableColumn,
    { field: "eventEndDate", title: this.translate.instant("organizer_info.eventEndDate"), hide: false } as DatatableColumn,
  ];

  constructor(private companiesService: OrganizersService, private route: ActivatedRoute, public translate: TranslateService, private positioningService: PositioningService) {

    this.monthlyChartOptions = {};
  }
  fetchCompanyRecords() {
    this.companiesService.getOrganizersRecords(this.id, this.pageNumber, this.pageSize, this.filterType).subscribe((response) => {
      this.sales = response.body;

      const pagination = JSON.parse(response.headers.get("X-Pagination") || "");
      this.pageNumber = pagination.CurrentPage;
      this.pageSize = pagination.PageSize;
      this.totalRows = pagination.TotalCount;
    });
  }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.fetchCompanyStatistics();

      this.fetchOrganizersRecords();
      this.exportApiUrl = `api/organizers/organizer-plans/${this.id}`;
    });
  }
  fetchOrganizersRecords() {
    +this.companiesService.getOrganizersRecords(this.id, this.pageNumber, this.pageSize, this.filterType).subscribe((response) => {
      this.sales = response.body;
      const pagination = JSON.parse(response.headers.get("X-Pagination") || "");
      this.pageNumber = pagination.CurrentPage;
      this.pageSize = pagination.PageSize;
      this.totalRows = pagination.TotalCount;
    });
  }

  updateColumn(col: colDef) {
    col.hide = !col.hide;
    this.cols = [...this.cols];

  }

  changeServer(data: any) {
    this.pageSize = data.pagesize;
    this.pageNumber = data.current_page;
    this.fetchCompanyRecords();
    this.fetchCompanyStatistics();
  }

  fetchCompanyStatistics() {
    this.companiesService.getOrganizersStatistics(this.id).subscribe((response) => {
      this.organizerData = response;
      this.additionalInfo = [response.name, response.surname, response.email].filter((value) => value).join(" ");

      const visitsPerYear: { [key: string]: number } = {};

      this.organizerData.webChartData.forEach((data) => {
        const year = data.year.toString();
        visitsPerYear[year] = (visitsPerYear[year] || 0) + data.numberOfPlans;
      });

      this.chartData = Object.keys(visitsPerYear).map((year) => ({
        x: year,
        y: visitsPerYear[year],
      }));

      this.chartOptions = {
        series: [
          {
            name: "Visits",
            data: this.chartData,
            color: "#46b196",
          },
        ],
        chart: {
          height: 350,
          type: "bar",
          events: {
            dataPointSelection: (event: any, chartContext: any, config: any) => {
              const year = config.w.config.series[0].data[config.dataPointIndex].x;
              this.showMonthlyChart(year);
            },
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
            barHeight: "40%",
            dataLabels: {
              position: "bottom",
            },
            colors: {
              ranges: [
                { from: 0, to: 5, color: "#FFB6C1" },
                { from: 6, to: 10, color: "#FF69B4" },
                { from: 11, to: 15, color: "#FF1493" },
                { from: 16, to: 20, color: "#C71585" },
                { from: 21, to: 25, color: "#DA70D6" },
                { from: 26, to: 30, color: "#BA55D3" },
                { from: 31, color: "#8A2BE2" },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1,
            },
          },
        },
        dataLabels: { enabled: false },
        xaxis: {
          type: "category",
          categories: this.chartData.map((item) => item.x),
        },

        yaxis: { title: { text: "" } },
        legend: { show: false },
      };
    });
  }

  showMonthlyChart(year: string) {
    const monthlyDataForYear = this.organizerData.webChartData.filter((data) => data.year.toString() === year);

    const groupedData = new Map<string, number>();
    for (const data of monthlyDataForYear) {
      const date = new Date();
      date.setMonth(data.month - 1);
      const monthName = date.toLocaleString("en-US", { month: "long" });

      const currentCount = groupedData.get(monthName) || 0;
      groupedData.set(monthName, currentCount + data.numberOfPlans);
    }

    const monthlyChartData = Array.from(groupedData.entries()).map(([month, numberOfPlans]) => ({ x: month, y: numberOfPlans }));

    const monthlyChartOptions = {

      series: [
        {
          name: "",
          data: monthlyChartData.map((item) => item.y),
          color: "#46b196",
        },
      ],
      chart: { height: 350, type: "line", toolbar: { show: false } },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "40%",
          endingShape: "rounded",
        },
      },
      colors: monthlyChartData.map(() => "#4CAF50"),
      dataLabels: { enabled: false },
      xaxis: { categories: monthlyChartData.map((item) => item.x), title: { text: "" } },
      yaxis: { title: { text: "" } },
      legend: { show: false },
    };

    this.monthlyChartOptions = monthlyChartOptions;
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

}
