import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-entity-form',
  templateUrl: './entity-form.component.html',
})
export class EntityFormComponent implements OnInit {
  @Input() editMode = false;
  @Input() entityData: any = {}; 
  @Input() isLoading = false;
  @Output() formSubmit = new EventEmitter<any>();
  
  form!: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    if (this.entityData) {
      this.populateForm(this.entityData);
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      address: [''],
      phoneNumber: [''],
      email: ['', [Validators.email]],
      representative: [''],
    });
  }

  populateForm(data: any): void {
    this.form.patchValue({
      name: data.name,
      address: data.address,
      phoneNumber: data.phoneNumber,
      email: data.email,
      representative: data.representative,
    });
  }

  submitForm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.formSubmit.emit(this.form.value);
  }
}
