<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
    <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="name">{{ 'customer_create.name' | translate }}</label>
      <input id="name" type="text" placeholder="{{ 'customer_create.name' | translate }}" class="form-input" formControlName="name" />
      <small class="text-danger" *ngIf="form.controls['name'].touched && form.controls['name'].errors">{{ 'customer_create.please_provide_name' | translate }}</small>
    </div>
    <div [ngClass]="form.controls['surname'].touched ? (form.controls['surname'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="surname">{{ 'customer_create.surname' | translate }}</label>
      <input id="surname" type="text" class="form-input" formControlName="surname" placeholder="{{ 'customer_create.surname' | translate }}" />
      <small class="text-danger" *ngIf="form.controls['surname'].touched && form.controls['surname'].errors">{{ 'customer_create.please_provide_surname' | translate }}</small>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
    <div [ngClass]="form.controls['email'].touched ? (form.controls['email'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="email">{{ 'customer_create.email' | translate }}</label>
      <input id="email" type="email" class="form-input" formControlName="email" placeholder="{{ 'customer_create.email' | translate }}" />
      <small class="text-danger" *ngIf="form.controls['email'].touched && form.controls['email'].errors">{{ 'customer_create.please_provide_email' | translate }}</small>
    </div>
    <div [ngClass]="form.controls['dateOfBirth'].touched ? (form.controls['dateOfBirth'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customDob">{{ "customer_create.date_of_birth" | translate }}</label>
      <input
        id="customDob"
        type="text"
        placeholder="{{ 'customer_create.enter_date_of_birth' | translate }}"
        class="form-input"
        formControlName="dateOfBirth"
        placeholder="__.__.____"
        [textMask]="{ mask: dateMask }"
      />
      <ng-container *ngIf="form.controls['dateOfBirth'].touched && !form.controls['dateOfBirth'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "customer_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['dateOfBirth'].touched && form.controls['dateOfBirth'].errors">
        <p class="mt-1 text-danger">{{ "customer_create.please_provide_date_of_birth" | translate }}</p>
      </ng-container>
    </div>
    
  </div>
  <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
    <div [ngClass]="form.controls['phoneNumber'].touched ? (form.controls['phoneNumber'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="phoneNumber">{{ 'customer_create.phone' | translate }}</label>
      <input id="phoneNumber" type="text" class="form-input" formControlName="phoneNumber" placeholder="{{ 'customer_create.phone' | translate }}" />
      <small class="text-danger" *ngIf="form.controls['phoneNumber'].touched && form.controls['phoneNumber'].errors">{{ 'customer_create.please_provide_phone' | translate }}</small>
    </div>
    <div [ngClass]="form.controls['address'].touched ? (form.controls['address'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
      <label for="address">{{ 'customer_create.address' | translate }}</label>
      <input id="address" type="text" class="form-input" formControlName="address" placeholder="{{ 'customer_create.address' | translate }}" />
      <small class="text-danger" *ngIf="form.controls['address'].touched && form.controls['address'].errors">{{ 'customer_create.please_provide_address' | translate }}</small>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
  <div [ngClass]="form.controls['imbg'].touched ? (form.controls['imbg'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="imbg">{{ 'customer_create.imbg' | translate }}</label>
    <input id="imbg" type="text" class="form-input" formControlName="imbg" placeholder="{{ 'customer_create.imbg' | translate }}" />
    <small class="text-danger" *ngIf="form.controls['imbg'].touched && form.controls['imbg'].errors">{{ 'customer_create.please_provide_address' | translate }}</small>
  </div>
  <div [ngClass]="form.controls['profession'].touched ? (form.controls['profession'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="profession">{{ 'customer_create.profession' | translate }}</label>
    <input id="profession" type="text" class="form-input" formControlName="imbg" placeholder="{{ 'customer_create.profession' | translate }}" />
    <small class="text-danger" *ngIf="form.controls['profession'].touched && form.controls['profession'].errors">{{ 'customer_create.please_provide_address' | translate }}</small>
  </div>
  </div>
  
  <div [ngClass]="form.controls['passport'].touched ? (form.controls['passport'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="passport">{{ 'customer_create.passport' | translate }}</label>
    <input id="passport" type="text" class="form-input" formControlName="passport" placeholder="{{ 'customer_create.address' | translate }}" />
    <small class="text-danger" *ngIf="form.controls['passport'].touched && form.controls['passport'].errors">{{ 'customer_create.please_provide_address' | translate }}</small>
  </div>
  <button type="submit" class="btn btn-primary w-full" [disabled]="isLoading">
    <ng-container *ngIf="!isLoading; else loadingTemplate">{{ editMode ? "Update" : "Save" }}</ng-container>
    <ng-template #loadingTemplate> <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle"></span> {{ "loading" | translate }} </ng-template>
  </button>
</form>
