<form [formGroup]="form" (ngSubmit)="submitForm()" class="w-full">
  <div class="grid grid-cols-1 gap-5 sm:grid-cols-1">
  <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="name">{{ editMode ? "Update" : "Add" }} {{"entity_form.name" | translate}}</label>
    <input id="name" type="text" placeholder="Enter name" class="form-input" formControlName="name" />
    <small class="text-danger" *ngIf="form.controls['name'].touched && form.controls['name'].errors"></small>
  </div>
  </div>
  <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
  <div [ngClass]="form.controls['address'].touched ? (form.controls['address'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="address">{{ editMode ? "Update" : "Add" }} {{"entity_form.address" | translate}}</label>
    <input id="address" type="text" placeholder="Enter address" class="form-input" formControlName="address" />
    <small class="text-danger" *ngIf="form.controls['address'].touched && form.controls['address'].errors"></small>
  </div>
  <div [ngClass]="form.controls['phoneNumber'].touched ? (form.controls['phoneNumber'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="phoneNumber">{{ editMode ? "Update" : "Add" }} {{ "entity_form.phoneNumber" | translate}}</label>
    <input id="phoneNumber" type="text" placeholder="Enter phone number" class="form-input" formControlName="phoneNumber" />
    <small class="text-danger" *ngIf="form.controls['phoneNumber'].touched && form.controls['phoneNumber'].errors"></small>
  </div>
  </div>
  <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
  <div [ngClass]="form.controls['email'].touched ? (form.controls['email'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="email">{{ editMode ? "Update" : "Add" }} {{ "entity_form.email" | translate}}</label>
    <input id="email" type="email" placeholder="Enter email" class="form-input" formControlName="email" />
    <small class="text-danger" *ngIf="form.controls['email'].touched && form.controls['email'].errors"></small>
  </div>
  <div [ngClass]="form.controls['representative'].touched ? (form.controls['representative'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
    <label for="representative">{{ editMode ? "Update" : "Add" }} {{ "entity_form.representative" | translate}}</label>
    <input id="representative" type="text" placeholder="Enter representative" class="form-input" formControlName="representative" />
    <small class="text-danger" *ngIf="form.controls['representative'].touched && form.controls['representative'].errors"></small>
  </div>
  </div>
  <button type="submit" class="btn btn-primary w-full" [disabled]="isLoading">
    <ng-container *ngIf="!isLoading; else loadingTemplate">{{ editMode ? "Update" : "Save" }}</ng-container>
    <ng-template #loadingTemplate> <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle"></span> {{ "loading" | translate }} </ng-template>
  </button>
</form>
