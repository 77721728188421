import { Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import { Customer } from "../models/customer";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { PopupType } from "src/app/shared/enums/popup-types";
import { MessagePortalComponent } from "src/app/portal/portal";
import { CustomersService } from "../services/customers.service";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TableService } from "src/app/shared/services/table.service";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { animate, style, transition, trigger } from "@angular/animations";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { CustomerCreateEditComponent } from "src/app/shared/components/customer-create-edit/customer-create-edit.component";

@Component({
  templateUrl: "./customers.table.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class CustomersTableComponent extends BaseTableComponent<Customer> implements OnInit {
  public customers: Customer[] = [];

  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  @ViewChild("modal") modal!: ModalComponent;
  @ViewChild(CustomerCreateEditComponent) formComponent!: CustomerCreateEditComponent;
  editMode = false;
  foundCustomer: Customer[] = [];
  filteredCustomer: Customer[] = [];
  selectedCustomerIds: number[] = [];
  customer!: Observable<Customer[]>;
  mergeMode = false;
  name: any = "";
  term: any;
  customerid = 0;
  selectedCustomer: Customer[] = [];
  customerData:any = {};
  isLoading!:false;

  constructor(
    private positioningService: PositioningService,
    private customersService: CustomersService,
    public override translate: TranslateService,
    public override store: Store<AppState>,
    public override tableService: TableService,
    private ref: ChangeDetectorRef
  ) {
    super(store, translate, selectFromTo, tableService, `customers/paginated`);
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }
  override ngOnInit() {
    super.ngOnInit();

    this.loadCustomers();
  }
  loadCustomers() {
    this.filteredCustomer = [];
    this.foundCustomer = [];
    this.selectedCustomerIds = [];
    this.customer = this.customersService.getCustomersAll().pipe(map((company: Customer[]) => company.filter((organizer) => organizer.id !== this.customerid)));
  }
  addMerge(id: number, name: any) {
    this.customerid = id;
    this.name = name;
    this.mergeMode = true;
    this.modal.open();
    this.foundCustomer = [];
    this.filteredCustomer = [];
    this.term = "";
  }
  private fetchCustomers() {
    this.customersService.getCustomersWithHeaders(this.pageNumber, this.pageSize, this.filterType).subscribe((response) => {
      this.customers = response.body;
      const pagination = JSON.parse(response.headers.get("X-Pagination") || "");
      this.pageNumber = pagination.CurrentPage;
      this.pageSize = pagination.PageSize;
      this.totalRows = pagination.TotalCount;
    });
  }

  override getColumns(): void {
    this.cols = [
      { field: "id", title: "ID", isUnique: true, hide: true } as DatatableColumn,
      { field: "id", title: this.translate.instant("customer"), hide: false } as DatatableColumn,
      { field: "email", title: this.translate.instant("customer_info.email"), hide: false } as DatatableColumn,
      { field: "phoneNumber", title: this.translate.instant("customer_info.phone_number"), hide: false } as DatatableColumn,
      { field: "passport", title: this.translate.instant("customer_info.passport"), hide: true } as DatatableColumn,
      { field: "numberOfTravels", title: this.translate.instant("customer_info.number_of_travels"), hide: true } as DatatableColumn,
      { field: "numberOfTickets", title: this.translate.instant("customer_info.number_of_tickets"), hide: false } as DatatableColumn,
      { field: "notPaidPlansCount", title: this.translate.instant("customer_info.not_paid_plans_count"), hide: true } as DatatableColumn,
      { field: "profession", title: this.translate.instant("customer_info.profession"), hide: true, sort: false } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("customer_info.created_at_formatted"), hide: true } as DatatableColumn,
      { field: "actions", title: this.translate.instant("customer_info.actions"), hide: false, sort: false } as DatatableColumn,
    ];
  }
  public exportCols = [
    { field: "customerNameAndSurname", title: this.translate.instant("customers-export.customerNameAndSurname"), isUnique: true, hide: true } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("customers-export.customerPhoneNumber"), hide: false, sort: false } as DatatableColumn,
    { field: "email", title: this.translate.instant("customers-export.email"), hide: false } as DatatableColumn,
    { field: "dateOfBirth", title: this.translate.instant("customers-export.dateOfBirth"), hide: false } as DatatableColumn,
    { field: "passportNumber", title: this.translate.instant("customers-export.passportNumber"), hide: false } as DatatableColumn,
    { field: "service", title: this.translate.instant("customers-export.service"), hide: false } as DatatableColumn,
    { field: "serviceDate", title: this.translate.instant("customers-export.serviceDate"), hide: true, sort: false } as DatatableColumn,
    { field: "purchaseCount", title: this.translate.instant("customers-export.purchaseCount"), hide: true, sort: false } as DatatableColumn,
    { field: "travel", title: this.translate.instant("customers-export.travel"), hide: true, sort: false } as DatatableColumn,
    { field: "airTicket", title: this.translate.instant("customers-export.airTicket"), hide: true, sort: false } as DatatableColumn,
    { field: "busTicket", title: this.translate.instant("customers-export.busTicket"), hide: true, sort: false } as DatatableColumn,
    { field: "groupTravel", title: this.translate.instant("customers-export.groupTravel"), hide: true, sort: false } as DatatableColumn,
    { field: "otherServices", title: this.translate.instant("customers-export.otherServices"), hide: true, sort: false } as DatatableColumn,
    { field: "profession", title: this.translate.instant("customers-export.profession"), hide: true, sort: false } as DatatableColumn,
    { field: "createdAt", title: this.translate.instant("customers-export.createdAt"), hide: true, sort: false } as DatatableColumn,
    { field: "totalEarnings", title: this.translate.instant("customers-export.totalEarnings"), hide: true, sort: false } as DatatableColumn,
  ];

  mergeSelectedCustomer() {
    this.selectedCustomerIds = this.selectedCustomer.map((person: Customer) => person.id);

    if (this.selectedCustomerIds.length > 0 && this.customerid) {
      this.customersService.mergeCustomer(this.customerid, this.selectedCustomerIds).subscribe((response) => {
        if (response.includes("Merged customers")) {
          showMessage(PopupType.Success, this.translate.instant("hotel_data.hotels_merged_successfully"));
          this.modal.close();
        } else {
          showMessage(PopupType.Warning, this.translate.instant("hotel_data.error_merging_hotels"));
        }
      });
    } else {
      showMessage(PopupType.Warning, this.translate.instant("hotel_data.select_hotels_to_merge"));
    }
  }
  addCustomer() {
    this.editMode = false;
    this.mergeMode = false;
    this.modal.open();
  }
  editCustomer(customer: Customer) {
    this.editMode = true;
    this.mergeMode = false;
    this.customerid = customer.id;
    setTimeout(() => {
      if (this.formComponent) {
        this.formComponent.populateForm(customer);
      }
    }, 0);
    this.modal.open();
  }
  onFormSubmit(customerData: string) {
    if (!this.editMode) {
      this.customersService.createCustomerData(customerData).subscribe(
        () => {
          this.modal.close();
          this.fetchItems();
          showMessage(PopupType.Success, this.translate.instant("customer_data.added_customer_successfully"));
        },
        () => {
          showMessage(PopupType.Warning, this.translate.instant("customer_data.error_adding_customer"));
        }
      );
    }
    else {
      this.customersService.updateCustomerData(this.customerid, customerData).subscribe(
        () => {
          this.modal.close();
          this.fetchItems();
          showMessage(PopupType.Success, this.translate.instant("customer_data.updated_customer_successfully"));
        },
        () => {
          showMessage(PopupType.Warning, this.translate.instant("customer_data.error_updating_customer"));
        }
      );
    }
  }
}
