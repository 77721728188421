import { Store } from "@ngrx/store";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { Sale } from "../../sales/models/sale";
import { HotelStatistics } from "../models/hotel";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApexChart, ApexLegend } from "ng-apexcharts";
import { AppState } from "src/app/store/index.selector";
import { HotelsService } from "../services/hotels.service";
import { TableService } from "src/app/shared/services/table.service";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { MessagePortalComponent } from "src/app/portal/portal";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { animate, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "app-hotel",
  templateUrl: "./hotel.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class HotelComponent extends BaseTableComponent<Sale> {

  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  private id!: number;
  protected hotelData!: HotelStatistics;
  public chartOptions: any;
  public chartData: any[] = [];
  public sales: Sale[] = [];
  monthlyChartOptions: any;
  public exportApiUrl!: string;
  public additionalInfo!: string;

  constructor(
    private hotelsService: HotelsService,
    public override translate: TranslateService,
    public override store: Store<AppState>,
    public override tableService: TableService,
    private route: ActivatedRoute,
    private positioningService: PositioningService
  ) {
    super(store, translate, selectFromTo, tableService, "");
  }

  override ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.apiUrl = `hotels/plans/${this.id}`;
      this.exportApiUrl = `api/Hotels/hotel-plans/export/${this.id}`;
      super.ngOnInit();
      this.fetchHotelStatistics();
    });
  }

  override getColumns(): void {
    this.cols = [
      { field: "id", title: "ID", isUnique: true, hide: true } as DatatableColumn,
      { field: "entity_name", title: this.translate.instant("hotelinfo.entity_name"), hide: false } as DatatableColumn,
      { field: "customerFullName", title: this.translate.instant("hotelinfo.customerFullName"), hide: false } as DatatableColumn,
      { field: "destinations", title: this.translate.instant("hotelinfo.destination"), hide: false } as DatatableColumn,
      { field: "number_of_passangers", title: this.translate.instant("hotelinfo.number_of_passangers"), hide: false } as DatatableColumn,
      { field: "getStatus", title: this.translate.instant("hotelinfo.status"), hide: false } as DatatableColumn,
      { field: "bruto", title: this.translate.instant("hotelinfo.bruto"), hide: false } as DatatableColumn,
      { field: "depth", title: this.translate.instant("hotelinfo.depth"), hide: false } as DatatableColumn,
      { field: "createdAtFormated", title: this.translate.instant("hotelinfo.sold"), hide: false } as DatatableColumn,
    ];
  }

  exportCols = [
    { field: "customerNameAndSurname", title: this.translate.instant("companyexport.customerNameAndSurname"), hide: false, sort: false } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("companyexport.phoneNumber"), hide: false } as DatatableColumn,
    { field: "numberOfPersons", title: this.translate.instant("companyexport.numberOfPersons"), hide: false } as DatatableColumn,
    { field: "arrivalDate", title: this.translate.instant("companyexport.country"), hide: false } as DatatableColumn,
    { field: "departureDate", title: this.translate.instant("companyexport.departureDate"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("companyexport.bruto"), hide: false, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("companyexport.neto"), hide: false, sort: false } as DatatableColumn,
    { field: "profit", title: this.translate.instant("companyexport.profit"), hide: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("companyexport.status"), hide: false } as DatatableColumn,
    { field: "dateOfPurchase", title: this.translate.instant("companyexport.purchaseDate"), hide: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("companyexport.user"), hide: false } as DatatableColumn,
  ];

  chart: ApexChart = {
    height: 300,
    type: "radar",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  };
  legend: ApexLegend = {
    position: "bottom",
  };

  pieChart = {
    series: [
      {
        name: "Series 1",
        data: [80, 50, 30, 40, 100, 20, 12, 12, 12, 12, 12, 12],
        color: "#46b196",
      },
    ],
    labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
    colors: ["#4361ee"],
    xaxis: {
      categories: ["January", "February", "March", "April", "May", "June", "July", "Avgust", "September", "October", "November", "December"],
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColors: "#e0e6ed",
          connectorColors: "#e0e6ed",
        },
      },
    },
    tooltip: {
      theme: "light",
    },
  };

  fetchHotelRecords() {
    this.hotelsService.getHotelsRecords(this.id, this.pageNumber, this.pageSize, this.filterType).subscribe((response) => {
      this.sales = response.body;
      const pagination = JSON.parse(response.headers.get("X-Pagination") || "");
      this.pageNumber = pagination.CurrentPage;
      this.pageSize = pagination.PageSize;
      this.totalRows = pagination.TotalCount;
    });
  }

  fetchHotelStatistics() {
    this.hotelsService.getHotelStatistics(this.id).subscribe((response) => {
      this.hotelData = response;
      this.additionalInfo = [response.name, response.address, response.phoneNumber, response.email].filter(value => value).join(' ');
      const visitsPerYear: { [key: string]: number } = {};

      this.hotelData.webChartData.forEach((data) => {
        const year = data.year.toString();
        visitsPerYear[year] = (visitsPerYear[year] || 0) + data.numberOfPlans;
      });

      this.chartData = Object.keys(visitsPerYear).map((year) => ({
        x: year,
        y: visitsPerYear[year],
      }));


      const chartHeight = this.chartData.length < 3 ? 150 : 250;
      const chartWidth = this.chartData.length < 3 ? 150 : 250;

      this.chartOptions = {
        series: [
          {
            name: "Visits",
            data: this.chartData.map(item => item.y),
            color: "#46b196",
          },
        ],
        chart: {
          height: chartHeight,
          width: chartWidth,
          type: "bar",
          events: {
            dataPointSelection: (event: any, chartContext: any, config: any) => {
              const year = this.chartData[config.dataPointIndex]?.x; // Accessing year from chartData
              this.showMonthlyChart(year);
            },
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
            barHeight: "75%",
            dataLabels: {
              position: "bottom",
            },
            colors: {
              ranges: [
                { from: 0, to: 5, color: "#FFB6C1" },
                { from: 6, to: 10, color: "#FF69B4" },
                { from: 11, to: 15, color: "#FF1493" },
                { from: 16, to: 20, color: "#C71585" },
                { from: 21, to: 25, color: "#DA70D6" },
                { from: 26, to: 30, color: "#BA55D3" },
                { from: 31, color: "#8A2BE2" },
              ],
              backgroundBarColors: [],
              backgroundBarOpacity: 1
            },
          },
        },
        dataLabels: { enabled: false },
        xaxis: {
          type: "category",
          categories: this.chartData.map((item) => item.x),
        },
        yaxis: { title: { text: "" } },
        legend: { show: false },
      };


    });
  }

  showMonthlyChart(year: string) {
    const monthlyDataForYear = this.hotelData.webChartData.filter((data) => data.year.toString() === year);
    const chartWidth = this.chartData.length < 3 ? 150 : 250;

    const groupedData = new Map<string, number>();
    for (const data of monthlyDataForYear) {
      const date = new Date();
      date.setMonth(data.month - 1); // Months in JavaScript start from 0
      const monthName = date.toLocaleString("en-US", { month: "long" });

      const currentCount = groupedData.get(monthName) || 0;
      groupedData.set(monthName, currentCount + data.numberOfPlans);
    }

    const monthlyChartData = Array.from(groupedData.entries()).map(([month, numberOfPlans]) => ({ x: month, y: numberOfPlans }));

    const chartHeight = monthlyChartData.length < 3 ? 150 : 250;


    this.monthlyChartOptions = {
      series: [{
        name: "Monthly Visits", data: monthlyChartData.map((item) => item.y),
        color: "#46b196",
      }],
      chart: { height: chartHeight, type: "line" },
      plotOptions: {
        bar: {
          horizontal: true,
          width: chartWidth,
          endingShape: "rounded",
          colors: monthlyChartData.map(() => "#4CAF50"),
        },
      },
      dataLabels: { enabled: false },
      xaxis: { categories: monthlyChartData.map((item) => item.x) },
      yaxis: { title: { text: "" } },
      legend: { show: false },
    };
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }
}