<div class="space-y-8">
  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <div class="relative">
        <input [(ngModel)]="search" (keydown.enter)="onSearchEnter()" type="text" class="form-input pr-10" placeholder="{{ 'search_placeholder' | translate }}" />
        <span class="absolute inset-y-0 right-0 flex items-center pr-3">
          <icon-search class="text-gray-500"></icon-search>
        </span>
      </div>
      <div class="flex w-full flex-wrap items-center gap-5 md:justify-end ltr:ml-auto rtl:mr-auto">
        <button type="button" class="btn btn-outline-primary flex" (click)="addCompany()">
          <icon-plus class="ltr:mr-2 rtl:ml-2" />
          {{ "company_data.add_company" | translate }}
        </button>
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <app-export-table
          [exportApi]="'api/Companies/export'"
          [filterType]="filterType"
          [sortColumn]="sortColumn"
          [sortDirection]="sortDirection"
          [search]="search"
          [columns]="exportCols"
          [filename]="'Companies Table Export'"
        ></app-export-table>
      </div>
    </div>

    <div class="datatable">
      <ng-datatable
        [rows]="items"
        [stickyHeader]="true"
        [height]="'670px'"
        [columns]="cols"
        [sortable]="true"
        [loading]="loading"
        [isServerMode]="true"
        [search]="search"
        [pageSize]="pageSize"
        [totalRows]="totalRows"
        (changeServer)="changeServer($event)"
        skin="whitespace-nowrap table-hover"
        [paginationInfo]="'paginationInfoLabel' | translate"
        [noDataContent]="'empty_tables' | translate"
      >
        <ng-template slot="actions" let-value="data">
          <div class="flex items-center gap-3">
            <div>
              <a [routerLink]="'/dashboard/companies/' + value.id" type="button" [ngxTippy]="translate.instant('company_info.stats')" class="btn btn-dark h-10 w-10 rounded-full p-0">
                <icon-info-circle />
              </a>
            </div>
            <div *appHasRole="['Admin', 'OfficeBranchManager']">
              <a type="button" class="btn btn-dark h-10 w-10 rounded-full p-0" [ngxTippy]="translate.instant('company_info.edit')" (click)="updateCompany(value)">
                <icon-pencil />
              </a>
            </div>
            <div *appHasRole="['Admin', 'OfficeBranchManager']">
              <a type="button" [ngxTippy]="translate.instant('company_info.delete')" class="btn btn-dark h-10 w-10 rounded-full p-0">
                <icon-trash-lines />
              </a>
            </div>
            <div>
              <a type="button" class="btn btn-dark h-10 w-10 rounded-full p-0" ngxTippy="{{ 'hotel_info.merge' | translate }}" (click)="addMerge(value.id, value.name)">
                <icon-plus />
              </a>
            </div>
          </div>
        </ng-template>
        <ng-template slot="actions" let-value="data">
          <div class="flex w-max items-center justify-between">
            <div hlMenu class="dropdown">
              <a hlMenuButton href="javascript:;">
                <icon-horizontal-dots class="rotate-90 opacity-70" />
              </a>
              <div #dropdownPlaceholder *hlMenuItems>
                <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" [routerLink]="'/dashboard/companies/' + value.id">
                      <icon-info-circle class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "details_quick_label" | translate }}
                    </a>
                  </li>
                  <li *appHasRole="['Admin', 'OfficeBranchManager']">
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="updateCompany(value)">
                      <icon-pencil class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "edit_quick_label" | translate }}
                    </a>
                  </li>
                  <li *appHasRole="['Admin', 'OfficeBranchManager']">
                    <a href="javascript:;" *hlMenuItem="let menuItem">
                      <icon-trash-lines class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "delete_quick_label" | translate }}
                    </a>
                  </li>
                  <li *appHasRole="['Admin', 'OfficeBranchManager']">
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="addMerge(value.id, value.name)">
                      <icon-plus class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "merge_quick_label" | translate }}
                    </a>
                  </li>
                </app-message-portal>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template slot="ticketsCount" let-value="data">
          {{ value.ticketsCount }}
        </ng-template>
        <ng-template slot="createdAt" let-value="data">
          {{ value.createdAt | date : "dd.MM.yyyy" }}
        </ng-template>
        <ng-template slot="numberOfPeople" let-value="data">
          <span>{{ value.numberOfPeople }} </span>
        </ng-template>
        <ng-template slot="earnings" let-value="data">
          <span>{{ value.earnings | currency : "EUR" }} </span>
        </ng-template>
      </ng-datatable>
    </div>
  </div>
  <modal #modal class="modal-center auth-modal">
    <ng-template #modalHeader>
      <div class="!py-5" *ngIf="!editMode && !mergeMode">
        {{ "company_data.add_company" | translate }}
      </div>
      <div class="!py-5" *ngIf="editMode && !mergeMode">
        {{ "company_data.update_company" | translate }}
      </div>
      <div class="!py-5" *ngIf="mergeMode">{{ name }} {{ "company_data.merge_duplicates" | translate }}</div>
    </ng-template>
    <ng-template #modalBody>
      <ng-container *ngIf="!mergeMode">
        <app-entity-form #formComponent [editMode]="editMode" [entityData]="companyData" [isLoading]="isLoading" (formSubmit)="onCompanyFormSubmit($event)"> </app-entity-form>
      </ng-container>
      <ng-container *ngIf="mergeMode">
        <ng-container *ngIf="company | async as companyList">
          <app-merge [items]="companyList" [selectedItemId]="companyId" [bindLabel]="'name'" (merge)="mergeSelectedCompany($event)"> </app-merge>
        </ng-container>
      </ng-container>
    </ng-template>
  </modal>
</div>
